import React from 'react';
import { Row, Col } from 'reactstrap';
import { updateModalLanguage } from 'store/apps/appConfig';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import CustomCard from 'components/common/cards/customCard';
import CustomButton from 'components/common/buttons/customButton';
import NavigationTitle from '../navigationTitle';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';

const LanguageModal = ({ language, theme }) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
    dispatch(updateModalLanguage(false))
  }

  return (
    <Row className='m-0 h-100'>
      <Power style={{ width: "185px", height: "auto", bottom: 15, left: "calc(50% - 92.5px)", fill: theme === "lightTheme" ? "var(--font)" : "var(--white)" }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: 100 }}>
        <NavigationTitle title={t('app.language')} modal />
      </Col>
      <Col xs="12" style={{ height: "calc(100% - 230px)" }}>
        <Row className='mt-4 py-5 h-100 d-flex'>
          <div className='h-auto'>
            {translationFlags.map((item, index) =>
              <Col key={index} xs="12" className='my-5' onClick={() => changeCountry(item)} style={{ height: 140 }}>
                <CustomCard color={language === item?.code ? "lightRose" : "secondary"}>
                  <div className="m-0 p-0 p-4 w-100 h-fit-content d-flex flex-row">
                    <div className='m-0 p-0' style={{ width: 100, height: "auto" }}>{item?.flag}</div>
                    <div className='m-0 p-0 d-flex align-items-center ps-4 text-3'>{item?.language}</div>
                  </div>
                </CustomCard>
              </Col>
            )}
          </div>
        </Row>
      </Col>
      <Col xs="12" style={{ height: 130 }}>
        <Row className='h-100 d-flex'>
          <Col xs="12">
            <CustomButton
              timer
              modal
              color="secondary"
              label={t("app.back")}
              textSize="text-3"
              onClick={() => dispatch(updateModalLanguage(false))}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state?.appConfig?.language,
    theme: state?.appConfig?.theme
  };
};

export default connect(mapStateToProps)(LanguageModal);
