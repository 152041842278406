import { ReactComponent as MBWay } from 'assets/images/logos/Logo_MBWay.svg'
import { ReactComponent as AmexCard } from 'assets/images/icons/AmexCard.svg'
import { ReactComponent as VisaCard } from 'assets/images/icons/VisaCard.svg'
import { ReactComponent as MasterCard } from 'assets/images/icons/MasterCard.svg'

export const routes = [
  {
    path: "/access-lockers",
    inativityTime: 10
  },
  {
    path: "/terms-conditions",
    inativityTime: 10
  },
  {
    path: "/assigned-locker",
    inativityTime: 10
  },
  {
    path: "/checkout",
    inativityTime: 10
  },
  {
    path: "/checkout-summary",
    inativityTime: 15
  },
  {
    path: "/collect-number",
    inativityTime: 10
  },
  {
    path: "/collect-pin",
    inativityTime: 10
  },
  {
    path: "/error",
    inativityTime: 10
  },
  {
    path: "/closed",
    inativityTime: 10
  },
  {
    path: "/home",
    inativityTime: false
  },
  {
    path: "/locker-size",
    inativityTime: 10
  },
  {
    path: "/invoice",
    inativityTime: 10
  },
  {
    path: "/open-locker",
    inativityTime: 10
  },
  {
    path: "/payment",
    inativityTime: 10
  },
  {
    path: "/payment/confirm",
    inativityTime: 10
  },
  {
    path: "/payment/refound",
    inativityTime: 10
  },
  {
    path: "/payment/fail",
    inativityTime: 10
  },
  {
    path: "/prices",
    inativityTime: 10
  },
  {
    path: "/recover-pin",
    inativityTime: 10
  },
  {
    path: "/release-locker",
    inativityTime: 10
  },
  {
    path: "/service-down",
    inativityTime: 10
  }
];

export const modals = [
  {
    name: "language",
    inativityTime: 10
  },
  {
    name: "faqs",
    inativityTime: 10
  },
  {
    name: "notifications",
    inativityTime: 10
  }
]


export const paymentMethods = [
  {
    method: "credit_card",
    name: "creditCard",
    icons: [
      <VisaCard className='p-0 h-auto' style={{ width: 70, margin: "0 5px 0 0" }} />,
      <MasterCard className='p-0 h-auto' style={{ width: 70, margin: "0 5px" }} />,
      <AmexCard className='p-0 h-auto' style={{ width: 70, margin: "0 0 0 5px" }} />
    ]
  },
  {
    method: "mbway",
    name: "MBWay",
    icons: [<MBWay className='m-0 p-0 h-auto' style={{ width: 85 }} />]
  }
]

export const pathnameSocketFail = ['/terms-conditions', '/locker-size', '/prices', '/collect-number', '/invoice', '/payment']

export const pathnamePaymentMissing = ['/terms-conditions', '/locker-size', '/prices', '/collect-number', '/invoice', '/collect-pin', '/payment']

export const pathnameCheckScheduleAndOffline = ['/terms-conditions', '/locker-size', '/prices', '/collect-number', '/collect-pin', '/invoice', '/payment']

export const pathnameCheckMyLockers = ['/collect-number', '/collect-pin']

export const pathnameCheckTerms = ['/locker-size', '/prices', '/collect-number', '/collect-pin', '/invoice', '/payment']