import React from 'react';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';

const CustomCard = ({color,children,modal,theme,request,border}) => {

  return (
    <Row onClick={request} className={`h-100 border-radius d-flex align-items-center justify-content-center`} style={{border: border ? border : "", color: color === "font" ? "var(--white)" : "",background: modal ? `var(${theme === "lightTheme" ? "--white" : "--font"})` : `var(--${color})`}}>
      {children}
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state?.appConfig?.theme
  };
};

export default connect(mapStateToProps)(CustomCard);
