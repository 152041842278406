import React, { Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import VerticalLayout from "layout/verticalLayout";

const Home = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const Prices = React.lazy(() => import(/* webpackChunkName: "views-prices" */ "views/prices"));
const CreatePin = React.lazy(() => import(/* webpackChunkName: "views/create-pin" */ "views/create-pin"));
const CreateNumber = React.lazy(() => import(/* webpackChunkName: "views/create-number" */ "views/create-number"));
const CollectPin = React.lazy(() => import(/* webpackChunkName: "views/collect-pin" */ "views/collect-pin"));
const OpenLocker = React.lazy(() => import(/* webpackChunkName: "views/open-locker" */ "views/open-locker"));
const Invoice = React.lazy(() => import(/* webpackChunkName: "views/invoice" */ "views/invoice"))
const AssignedLocker = React.lazy(() => import(/* webpackChunkName: "views/assigned-locker" */ "views/assigned-locker"))
const CollectNumber = React.lazy(() => import(/* webpackChunkName: "views/assigned-locker" */ "views/collect-number"))
const AccessLockers = React.lazy(() => import(/* webpackChunkName: "views-acess-lockers" */ "views/access-lockers"));
const CheckoutSummary = React.lazy(() => import(/* webpackChunkName: "views-checkout-summary" */ "views/checkout-summary"));
const Checkout = React.lazy(() => import(/* webpackChunkName: "views-checkout" */ "views/checkout"));
const Error = React.lazy(() => import(/* webpackChunkName: "views/error" */ "views/error"));
const Closed = React.lazy(() => import(/* webpackChunkName: "views/error/closed" */ "views/error/closed"));
const ServiceDown = React.lazy(() => import(/* webpackChunkName: "views/error/service-down" */ "views/error/service-down"));
const Payment = React.lazy(() => import(/* webpackChunkName: "views/payment" */ "views/payment"))
const RecoverPin = React.lazy(() => import(/* webpackChunkName: "views-recover-pin" */ "views/recover-pin"));
const ReleaseLocker = React.lazy(() => import(/* webpackChunkName: "views/release-locker" */ "views/release-locker"));
const ConfirmMbWay = React.lazy(() => import(/* webpackChunkName: "views/payment/confirmMbWay" */ "views/payment/confirmMbWay"));
const PaymentMbWayFail = React.lazy(() => import(/* webpackChunkName: "views/payment/paymentFail" */ "views/payment/paymentFail"));
const Refound = React.lazy(() => import(/* webpackChunkName: "views/payment/refound" */ "views/payment/refound"));
const LockerSize = React.lazy(() => import(/* webpackChunkName: "views/locker-size" */ "views/locker-size"));
const TermsAndConditions = React.lazy(() => import(/* webpackChunkName: "views/terms-conditions" */ "views/terms-conditions"));

const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <VerticalLayout>
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route exact path="/home">
              <Route index element={<Home />} />
            </Route>
            <Route exact path="/prices">
              <Route index element={<Prices />} />
            </Route>
            <Route exact path="/release-locker">
              <Route index element={<ReleaseLocker />} />
            </Route>
            <Route exact path="/create-pin">
              <Route index element={<CreatePin />} />
            </Route>
            <Route exact path="/create-number">
              <Route index element={<CreateNumber />} />
            </Route>
            <Route exact path="/collect-pin">
              <Route index element={<CollectPin />} />
            </Route>
            <Route exact path="/open-locker">
              <Route index element={<OpenLocker />} />
            </Route>
            <Route exact path="/invoice">
              <Route index element={<Invoice />} />
            </Route>
            <Route exact path="/payment">
              <Route index element={<Payment />} />
            </Route>
            <Route exact path="/terms-conditions">
              <Route index element={<TermsAndConditions />} />
            </Route>
            <Route exact path="/payment/confirm">
              <Route index element={<ConfirmMbWay />} />
            </Route>
            <Route exact path="/locker-size">
              <Route index element={<LockerSize />} />
            </Route>
            <Route exact path="/payment/fail">
              <Route index element={<PaymentMbWayFail />} />
            </Route>
            <Route exact path="/payment/refound">
              <Route index element={<Refound />} />
            </Route>
            <Route exact path="/assigned-locker">
              <Route index element={<AssignedLocker />} />
            </Route>
            <Route exact path="/collect-number">
              <Route index element={<CollectNumber />} />
            </Route>
            <Route exact path="/access-lockers">
              <Route index element={<AccessLockers />} />
            </Route>
            <Route exact path="/checkout-summary">
              <Route index element={<CheckoutSummary />} />
            </Route>
            <Route exact path="/checkout">
              <Route index element={<Checkout />} />
            </Route>
            <Route exact path="/error">
              <Route index element={<Error />} />
            </Route>
            <Route exact path="/closed">
              <Route index element={<Closed />} />
            </Route>
            <Route exact path="/service-down">
              <Route index element={<ServiceDown />} />
            </Route>
            <Route exact path="/recover-pin">
              <Route index element={<RecoverPin />} />
            </Route>
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
        </VerticalLayout>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
