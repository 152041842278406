import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** capacity check
export const capacityCheck = createAsyncThunk('general/capacityCheck', async (params) => {
  const response = await api.get(`api/alegro/capacity/check/${params}`)
  return response?.data
})

// ** cativation price check
export const cativationPriceCheck = createAsyncThunk('general/cativationPriceCheck', async (params) => {
  const response = await api.get(`api/marketplace/cativationPrice/list?type=${params}`)
  return response?.data
})

export const termsAndConditions = createAsyncThunk('general/termsAndConditions', async () => {
  const response = await api.get(`api/terms/list`)
  return response?.data
})

export const pendingTime = createAsyncThunk('general/pendingTime', async () => {
  const response = await api.get(`api/marketplace/pendingTime/list?type=visitor`)
  return response?.data
})

export const recentQrCodeToken = createAsyncThunk('general/recentQrCodeToken', async () => {
  const config = {
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    }
  };
  const response = await api.get(`api/alegro/recentQrCodeToken`, config)
  return response?.data
})


export const lockerSize = createAsyncThunk('general/lockerSize', async (params) => {
  const response = await api.get(`api/alegro/locker/sizes/${params?.locationId}`)
  return response?.data
})

export const checkToken = createAsyncThunk('general/checkToken', async (params) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/locker/checkToken`, params, config)
  return response?.data
})


const initialState = {
  capacity: null,
  sizes: false,
  qrCodeToken: false
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: { resetGeneralState: () => initialState },
  extraReducers: builder => {
    builder.addCase(recentQrCodeToken.fulfilled, (state, action) => {
      state.qrCodeToken = action.payload?.Data?.updatedToken
    })
    builder.addCase(capacityCheck.fulfilled, (state, action) => {
      if (action?.payload?.ResultCode === 1) {
        state.capacity = action.payload?.Data
      }
      else {
        state.capacity = action.payload?.Data
      }
    })
    builder.addCase(lockerSize.fulfilled, (state, action) => {
      if (action?.payload?.ResultCode === 1) {
        state.sizes = action.payload?.Data
      }
      else {
        state.sizes = false
      }
    })
  }
})

export const { resetGeneralState } = generalSlice.actions;
export default generalSlice.reducer
