import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'
import { checkoutInfo } from 'store/apps/checkout';

// ** Check User Number
export const checkNumber = createAsyncThunk('user/checkNumber', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/number/check`, values, config)
  return response?.data
})

// ** Set Active Locker
export const activeLocker = createAsyncThunk('user/activeLocker', async (params) => {
  return params
})

// ** Save user credentials
export const setUserCredentials = createAsyncThunk('user/setUserCredentials', async (params) => {
  return params
})

// ** Access User Lockers
export const accessUserLocker = createAsyncThunk('user/accessUserLocker', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/access`, values, config)
  return response?.data
})

// ** open Locker
export const openLocker = createAsyncThunk('user/openLocker', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/open`, values, config)
  return response?.data
})

// ** release Locker
export const releaseLocker = createAsyncThunk('user/releaseLocker', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/release`, values, config)
  return response?.data
})

// ** assign Locker
export const assignLocker = createAsyncThunk('user/assignLocker', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/locker/request`, values, config)
  return response?.data
})

// ** check Voucher
export const checkVoucher = createAsyncThunk('user/checkVoucher', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/alegro/voucher/check?code=${params}`)
  return response?.data
})

// ** fetch User Info
export const fetchUserInfo = createAsyncThunk('user/fetchUserInfo', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/alegro/user/info?numberPrefix=${params?.numberPrefix}&number=${params?.number}&pin=${params?.pin}`)
  return response?.data
})

// ** recoverPin
export const recoverPin = createAsyncThunk('user/recoverPin', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/pin/recover`, values, config)
  return response?.data
})
// update Free Locker
export const updateFreeLocker = createAsyncThunk('user/updateFreeLocker', async (params) => {
  return params
})

// update Accessibility
export const updateAccessibility = createAsyncThunk('user/updateAccessibility', async (params) => {
  return params
})

// update Accessibility
export const resetUserData = createAsyncThunk('user/resetUserData', async (params) => {
  return params
})

// ** update User
export const updateUser = createAsyncThunk('user/updateUser', async (params, { getState, dispatch }) => {
  const values = params;
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const response = await api.post(`api/alegro/user/update`, values, config)
  return response?.data
})

// ** Set User InvoiceData
export const setInvoiceData = createAsyncThunk('user/setInvoiceData', async (params) => {
  return params
})

// ** Set Locker Open
export const setLockerOpen = createAsyncThunk('user/setLockerOpen', async (params) => {
  return params
})

// ** Set Locker Checkout
export const setLockerCheckout = createAsyncThunk('user/setLockerCheckout', async (params) => {
  return params
})

// ** Update Terms and Conditions
export const updateTermsAndConditions = createAsyncThunk('user/updateTermsAndConditions', async (params) => {
  return params
})

// ** Set Locker Size
export const setLockerSize = createAsyncThunk('user/setLockerSize', async (params) => {
  return params
})


const initialState = {
  prefix: '',
  number: '',
  activeLockerId: '',
  activeLockerDoor: '',
  lockers: null,
  lockerSize: '',
  voucher: '',
  registerId: '',
  nif: '',
  email: '',
  invoice: false,
  freeLocker: false,
  accessibility: false,
  lockerOpened: false,
  lockerCheckout: false,
  termsAndConditions: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    resetVoucherUserInfo: (state) => {
      state.voucher = '';
      state.registerId = '';
      state.freeLocker = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(setUserCredentials.fulfilled, (state, action) => {
      state.number = action.meta?.arg?.number
      state.prefix = action.meta?.arg?.prefix
    })
    builder.addCase(accessUserLocker.fulfilled, (state, action) => {
      if (action?.payload?.ResultCode === 1) {
        state.lockers = action?.payload?.Data
        state.number = action.meta?.arg?.number
        state.prefix = action.meta?.arg?.numberPrefix
        if (action.payload?.Data?.length > 0) {
          state.nif = action.payload?.Data[0]?.locker_users[0]?.nif
          state.email = action.payload?.Data[0]?.locker_users[0]?.email
          state.invoice = action.payload?.Data[0]?.locker_users[0]?.invoice
        }
        else {
          state.lockers = false
        }
      }
      else {
        state.lockers = false
      }
    })
    builder.addCase(activeLocker.fulfilled, (state, action) => {
      state.activeLockerId = action.payload.lockerId
      state.activeLockerDoor = action.payload.doorNumber
    })
    builder.addCase(checkVoucher.fulfilled, (state, action) => {
      state.voucher = action?.payload?.Data?.voucher
      state.registerId = action?.payload?.Data?.registerId
    })
    builder.addCase(resetUserData.fulfilled, (state, action) => {
      state.prefix = action?.payload?.prefix
      state.number = action?.payload?.number
    })
    builder.addCase(updateAccessibility.fulfilled, (state, action) => {
      state.accessibility = action?.payload
    })
    builder.addCase(updateTermsAndConditions.fulfilled, (state, action) => {
      state.termsAndConditions = action?.payload
    })
    builder.addCase(updateFreeLocker.fulfilled, (state, action) => {
      state.freeLocker = action?.payload
    })
    builder.addCase(setLockerOpen.fulfilled, (state, action) => {
      state.lockerOpened = action?.payload
    })
    builder.addCase(setLockerSize.fulfilled, (state, action) => {
      state.lockerSize = action?.payload
    })
    builder.addCase(setLockerCheckout.fulfilled, (state, action) => {
      state.lockerCheckout = action?.payload
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.number = action.meta?.arg?.number
      state.prefix = action.meta?.arg?.numberPrefix
    })
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.nif = action?.payload?.Data?.nif ? action?.payload?.Data?.nif : ''
      state.email = action?.payload?.Data?.email ? action?.payload?.Data?.email : ''
      state.number = action?.payload?.Data?.number
      state.prefix = action?.payload?.Data?.numberPrefix
      state.invoice = action?.payload?.Data?.invoice
    })
    builder.addCase(assignLocker.fulfilled, (state, action) => {
      state.activeLockerId = action.payload.Data?.id
      state.activeLockerDoor = action.payload.Data?.doorNumber
    })
    builder.addCase(setInvoiceData.fulfilled, (state, action) => {
      state.nif = action.meta?.arg?.nif
      state.email = action.meta?.arg?.email
      state.invoice = action.meta?.arg?.invoice
    })
  }
})

export const { resetUserState,resetVoucherUserInfo } = userSlice.actions;
export default userSlice.reducer;
