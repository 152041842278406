import React from 'react';
import ReactDom from 'react-dom';

const Portal = ({ children, isOpen }) => {

  if (isOpen === false) return null;

  const styles = {
    container: {
      position: 'absolute',
      bottom: '4.5rem',
      left: '0',
      zIndex: '50',
      height: "calc(85% - 7.5rem)",
      width: '100%',
      backgroundColor: "var(--background)",
    }
  }

  return ReactDom.createPortal(
    <div style={styles.container} className="px-5 m-0">
      <div className='px-4 m-0 w-100 h-100'>
        {children}
      </div>
    </div>,
    document.getElementById('content-container') ? document.getElementById('content-container') : document.body
  )
}

export default Portal