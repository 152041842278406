import axios from 'axios';
import { useDispatch } from 'react-redux';
import { recentQrCodeToken } from 'store/apps/general';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer `,
  }
});

const AxiosInterceptor = ({ children }) => {
  const dispatch = useDispatch()
  axiosConfig.interceptors.request.use(
    function (config) {
      config.headers['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
      config.headers['token'] = window.localStorage.getItem('token');
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axiosConfig.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log(error);
      if (error.response.status === 401) {
        // Handle 401 error
      } else if (error.response.status === 403) {
        if (error?.response?.data?.error === 'qr_token_invalid' || error?.response?.data?.error === 'qr_token_expired') {
          return dispatch(recentQrCodeToken())
            .then((res) => {
              if (res?.payload?.ResultCode === 1) {
                window.localStorage.setItem('token', res?.payload?.Data?.updatedToken);
                const config = error.config;
                config.headers['token'] = res?.payload?.Data?.updatedToken;
                return axios.request(config);
              }
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }
      } else {
        return Promise.reject(error);
      }
    }
  );


  return children;
}

export default axiosConfig;
export { AxiosInterceptor };
