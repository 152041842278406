export const colors = {
  white: '#ffffff',
  black: '#090A0A',
  grey: '#686868',
  terra: '#FF0890',
  // font: '#411B14',
  font: "#2D3C3C",
  // disable: 'rgba(65, 27, 20, 0.3)',
  disable: 'rgba(45, 60, 60, 0.3)',
  // primary_shadow : '#6D2E22',
  primary_shadow: "#273535",
  error: "#dc3545",
  success:"#D7FF85",
  primary_disabled: "#813B2F",
  dark_opacity: 'rgba(9, 10, 10, 0.3)',
  secondaryButton_shaddow: "rgba(9, 10, 10, 0.05)",
  portalBackground: "rgba(245, 239, 225, 0.97)",
  'cream':{
     light: '#FEFAF0',
      dark: '#F5EFE1',
  }
}