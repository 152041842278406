import React from 'react';
import { Row } from 'reactstrap';
import Navbar from 'components/navbar';
import { useSelector } from 'react-redux';
import Loading from 'components/common/loading/Loading';
import Portal from 'helpers/Portal';
import FaqsModal from 'components/common/modals/faqsModal';
import LanguageModal from 'components/common/modals/languageModal';
import { useNavigate } from 'react-router-dom';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg'
import Socket from 'helpers/socket';
import NotificationsModal from 'components/common/modals/notificationsModal';

const VerticalLayout = ({ children }) => {

  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const { faqsModalOpen, languageModalOpen, notificationsModalOpen, loading, theme } = useSelector(state => state?.appConfig);

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const getModalOpen = () => {
    if (languageModalOpen) {
      return <LanguageModal />;
    }
    else if (faqsModalOpen) {
      return <FaqsModal />;
    }
    else if (notificationsModalOpen) {
      return <NotificationsModal />
    }
    else {
      return null;
    }
  }

  return (
    <Row className='m-0 vh-100 p-5 position-relative' style={{ background: "var(--background)" }}>
      <Socket token={process.env.REACT_APP_API_TOKEN} showIconStatus />
      {
        loading &&
        <Loading />
      }
      <Power style={{ width: "175px", height: "auto", bottom: 15, left: "calc(50% - 87.5px)", fill: theme === "lightTheme" ? "var(--font)" : "var(--white)" }} className="m-0 m p-0 position-absolute" />
      <div className='p-4 m-0 h-100 w-100'>
        <div className='m-0 p-0' style={{ height: "15%" }}>
          <Navbar />
        </div>
        <div id="content-container" className="m-0 p-0" style={{ height: "85%" }}>
          <Portal isOpen={languageModalOpen || faqsModalOpen || notificationsModalOpen}>
            {
              getModalOpen()
            }
          </Portal>
          {children}
        </div>
      </div>
    </Row>
  );
}

export default VerticalLayout;
