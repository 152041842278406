export const findPrices = (prices) => {
  return prices.every((item) => {
    const priceToCheck = item?.finalPrice !== undefined && item?.finalPrice !== null
      ? (item?.finalPrice !== false ? item.finalPrice : item?.price)
      : item?.price;

    return !(priceToCheck > 0);
  });
};

export const checkVisitingTime = (schedules, navTo) => {
  const lisbonTime = new Date().toLocaleTimeString('pt-PT', { timeZone: 'Europe/Lisbon', hour12: false, hour: '2-digit', minute: '2-digit' });
  const currentTime = lisbonTime.slice(0, 5);
  const startTime = schedules.start.slice(0, 5);
  const endTime = schedules.end.slice(0, 5);
  if (currentTime >= startTime && currentTime <= endTime) {
    return null;
  }
  else {
    navTo('/closed')
  }
};

